import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import compassImage from '../../assets/boussole.png';
import AppleLogo from '../../assets/apple_logo.svg'; // You'll need to add this asset
import {
  Container,
  LogoImage,
  GoogleButtonContent,
  Title,
  Wrapper,
  SendButton,
  Divider,
  NextButton,
  DividerLine,
  DividerText,
  SubTitle,
  GoogleIcon,
  ButtonGoogle,
  ButtonTextGoogle,
  ReturnButton,
  Input,
  Form,
  EmailLoginContainer,
  Message,
  CountdownText,
} from './styles';
import { useAuth } from '../../contexts/AuthContext';
import GoogleLogo from '../../assets/google_logo.png';
import { AlertOverlay, AlertBox, AlertMessage, AlertButton } from './styles';
import { ButtonApple, AppleButtonContent, AppleIcon, ButtonTextApple } from './styles';


const LoginPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationView, setShowVerificationView] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [isVerifying, setIsVerifying] = useState(false);
  const location = useLocation();
  const [isValidInput, setIsValidInput] = useState(false);
  const { login, error: authError } = useAuth();
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [hasEmailBeenSent, setHasEmailBeenSent] = useState(false);
  const TEST_EMAIL = "adminNoNeedForConfirmation@gmail.com";
  const [errorMessage, setErrorMessage] = useState('');
  const [showEmailExistsAlert, setShowEmailExistsAlert] = useState(false);
  const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

console.log('Apple Client ID:', process.env.REACT_APP_APPLE_CLIENT_ID);
console.log('Backend URL:', process.env.REACT_APP_BACKEND_URL);

  const handleBack = () => {
    if (showVerificationView) {
      setShowVerificationView(false);
      setVerificationCode('');
    } else {
      navigate(-1);
    }
  };

  const handleAlertConfirm = () => {
    setShowEmailExistsAlert(false);
    navigate('/login');
  };

  const handleNext = () => {
      if (hasEmailBeenSent) {
        setShowVerificationView(true);
      }
    };


    const checkEmailExists = async (email) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/check_email_exists/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        return data.exists;
      } catch (error) {
        console.error('Error checking email:', error);
        setErrorMessage('Erreur lors de la vérification de l\'email. Veuillez réessayer.');
        return false;
      }
    };
  

const handleLoginSuccess = useCallback(async (tokens, method, is_new_user = false, userData = null) => {
    console.log("handleLoginSuccess called with tokens:", tokens, "method:", method, "userData:", userData);
    console.log('Stored token:', localStorage.getItem('authToken'));

    try {
      await login(tokens, method, is_new_user, userData);
    } catch (error) {
      console.error('Error during login:', error);
      setVerificationMessage('Une erreur est arrivée. Veuillez réessayer ou envoyer un message au support si le problème persiste.');
    }
  }, [login]);


  const handleGoogleResponse = useCallback(async (response) => {
    setIsLoading(true);
    try {
      // First decode the JWT to get the email
      const decodedToken = JSON.parse(atob(response.credential.split('.')[1]));
      const email = decodedToken.email;

      // Check if email exists
      const emailExists = await checkEmailExists(email);
      
      if (!emailExists) {
        setShowEmailExistsAlert(true);
        setIsLoading(false);
        return;
      }

      // If email doesn't exist, proceed with Google authentication
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/google/callback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: response.credential
        }),
      });

      const data = await res.json();

      if (res.ok) {
        await handleLoginSuccess(data.token, 'google', data.is_new_user);
      } else {
        throw new Error(data.error || 'Failed to authenticate with Google');
      }
    } catch (error) {
      console.error('Error during Google authentication:', error);
      setErrorMessage('Une erreur est survenue lors de l\'authentification Google. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
      setIsLoadingGoogle(false);
    }
  }, [handleLoginSuccess]);

   const handleGoogleLogin = useCallback((e) => {
          e.preventDefault();
          setIsLoadingGoogle(true);

          if (window.google?.accounts?.id) {
            // Create a hidden button element
            const buttonDiv = document.createElement('div');
            buttonDiv.style.display = 'none';
            document.body.appendChild(buttonDiv);

            // Render the Google button
            window.google.accounts.id.renderButton(buttonDiv, {
              type: 'standard',
              theme: 'outline',
              size: 'large',
            });

            // Click the button programmatically
            const googleButton = buttonDiv.querySelector('div[role="button"]');
            if (googleButton) {
              googleButton.click();
              // Remove the hidden button after a short delay
              setTimeout(() => buttonDiv.remove(), 100);
            }
          } else {
            console.error('Google Sign-In API not loaded');
            setIsLoadingGoogle(false);
          }
        }, []);

        
    const handleAppleResponse = useCallback(async (event) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/apple/callback/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id_token: event.authorization.id_token,
            user: event.user || {} // Apple only sends user info on first login
          }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          await handleLoginSuccess(data.token, 'apple', data.is_new_user);
        } else {
          throw new Error(data.error || 'Failed to authenticate with Apple');
        }
      } catch (error) {
        console.error('Error during Apple authentication:', error);
        setErrorMessage('Une erreur est survenue lors de l\'authentification Apple. Veuillez réessayer.');
      } finally {
        setIsLoading(false);
      }
    }, [handleLoginSuccess]);


    const handleAppleLogin = useCallback(async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setErrorMessage('');
    
      try {
        if (!APPLE_CLIENT_ID) {
          throw new Error('Apple Sign In is not configured');
        }
    
        if (!window.AppleID) {
          throw new Error('Apple Sign In is not initialized');
        }
    
        // Add debug logs here
        console.log('=== Apple Login Attempt Debug Info ===');
        console.log('Current Redirect URI:', process.env.REACT_APP_APPLE_REDIRECT_URI);
        console.log('Current Backend URL:', process.env.REACT_APP_BACKEND_URL);
        console.log('AppleID Auth State:', window.AppleID.auth.initialized ? 'Initialized' : 'Not Initialized');
    
        if (!window.AppleID.auth.initialized) {
          const config = {
            clientId: APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: `${process.env.REACT_APP_BACKEND_URL}/auth/apple/callback/`,
            state: 'origin:web',
            usePopup: true
          };
          
          console.log('Reinitializing with config:', config);
          window.AppleID.auth.init(config);
        }
    
        const data = await window.AppleID.auth.signIn();
        console.log('Apple Sign In Response:', data);
        await handleAppleResponse(data);
      } catch (error) {
        console.error('Error during Apple Sign In:', error);
        console.error('Error details:', {
          message: error.message,
          stack: error.stack,
          error
        });
        setErrorMessage('Une erreur est survenue avec Apple Sign In. Veuillez réessayer.');
      } finally {
        setIsLoading(false);
      }
    }, [handleAppleResponse]);

    useEffect(() => {
      let isScriptLoaded = false;
      
      const loadAppleScript = async () => {
        try {
          if (!APPLE_CLIENT_ID) {
            console.error('Apple Client ID is not configured');
            return;
          }
    
          if (document.getElementById('appleid-signin-script')) {
            isScriptLoaded = true;
            return;
          }
    
          const script = document.createElement('script');
          script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
          script.id = 'appleid-signin-script';
          
          await new Promise((resolve, reject) => {
            script.onload = () => {
              isScriptLoaded = true;
              resolve();
            };
            script.onerror = (error) => reject(new Error(`Failed to load Apple Sign In script: ${error.message}`));
            document.head.appendChild(script);
          });
    
          if (window.AppleID && !window.AppleID.auth.initialized) {
            // Add debug logs here
            console.log('=== Apple Sign In Debug Info ===');
            console.log('Client ID:', APPLE_CLIENT_ID);
            console.log('Redirect URI:', process.env.REACT_APP_APPLE_REDIRECT_URI);
            console.log('Backend URL:', process.env.REACT_APP_BACKEND_URL);
            
            const config = {
              clientId: APPLE_CLIENT_ID,
              scope: 'name email',
              redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
              state: 'origin:web',
              usePopup: true,
              nonce: generateNonce(),
              responseMode: 'form_post'
            };
            
            console.log('Full Apple Config:', config);
            
            window.AppleID.auth.init(config);
          }
        } catch (error) {
          console.error('Error loading Apple Sign In:', error);
          isScriptLoaded = false;
        }
      };
    
      // Generate a random nonce
      const generateNonce = () => {
        const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz';
        let result = '';
        for (let i = 0; i < 32; i++) {
          result += charset[Math.floor(Math.random() * charset.length)];
        }
        return result;
      };
    
      loadAppleScript();
    
      return () => {
        if (isScriptLoaded) {
          const script = document.getElementById('appleid-signin-script');
          if (script) {
            script.remove();
          }
        }
      };
    }, []);

    useEffect(() => {
            const loadGoogleScript = async () => {
              // Load the Google Sign-In API script
              const script = document.createElement('script');
              script.src = 'https://accounts.google.com/gsi/client';
              script.async = true;
              script.defer = true;

              // Initialize Google Sign-In after script loads
              script.onload = () => {
                if (window.google?.accounts?.id) {
                  window.google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    callback: handleGoogleResponse,
                    auto_select: false,
                    cancel_on_tap_outside: true
                  });
                }
              };

              document.body.appendChild(script);
            };

            loadGoogleScript();

            return () => {
              const scriptElement = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
              if (scriptElement) {
                scriptElement.remove();
              }
            };
          }, [handleGoogleResponse]);

          const verifyEmailCode = useCallback(async (email, code) => {
            setIsVerifying(true);
            setIsLoading(true);
            try {
              console.log('Verifying code for email:', email);
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, code }),
              });
        
              const data = await response.json();
              console.log('Server response:', data);
        
              if (!response.ok) {
                throw new Error(data.error || `HTTP error! status: ${response.status}`);
              }
        
              if (data.token && data.token.access && data.token.refresh) {
                await handleLoginSuccess(data.token, 'email', data.is_new_user);
              } else {
                throw new Error('Invalid response from server');
              }
            } catch (error) {
              console.error('Error verifying email:', error);
              setVerificationMessage(error.message || 'Code expiré ou invalide. Veuillez réessayer.');
            } finally {
              setIsLoading(false);
              setIsVerifying(false);
            }
          }, [handleLoginSuccess]);

          const handleVerificationSubmit = async (e) => {
            e.preventDefault();
            await verifyEmailCode(email, verificationCode);
          };

  const verifyEmailToken = useCallback(async (token) => {
    setIsVerifying(true);
    setIsLoading(true);
    try {
      console.log('Verifying token:', token);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      console.log('Server response:', data);

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (data.token && data.token.access && data.token.refresh) {
        // Create a tokens object
        const tokens = {
          access: data.token.access,
          refresh: data.token.refresh,
        };
        await handleLoginSuccess(tokens, 'email', data.is_new_user);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setVerificationMessage(error.message || 'Lien expiré ou invalide. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
      setIsVerifying(false);
    }
  }, [handleLoginSuccess]);

const handleEmailSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  setErrorMessage('');

  try {
    if (email === TEST_EMAIL) {
      // Direct login for the test email
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/request_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.token && data.token.access && data.token.refresh) {
        // Directly call handleLoginSuccess to log in without verification
        await handleLoginSuccess(data.token, 'email', data.is_new_user, data.user);
      } else {
        throw new Error('Invalid response from server for test email');
      }
    } else {
      // Standard flow: send verification code
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/request_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setVerificationMessage('Verification code sent. Check your email.');
      setCountdown(180);
      setShowVerificationView(true);
      setHasEmailBeenSent(true);
    }
  } catch (error) {
    console.error('Error during email submission:', error);
    setErrorMessage('An error occurred. Please try again.');
  } finally {
    setIsLoading(false);
  }
};

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      verifyEmailToken(token);
    }
  }, [location, verifyEmailToken]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setVerificationMessage('');
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email); // This will return true or false explicitly
  };

  // And update your handleEmailChange to be more explicit
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
        setEmail(newEmail);
        setIsValidInput(isValidEmail(newEmail));
        setIsEmailSubmitted(false); // Reset submitted state when email changes
      };

      const handleVerificationCodeChange = (e) => {
        const newCode = e.target.value;
        setVerificationCode(newCode);
        setIsValidInput(newCode.length === 6);
      };

  useEffect(() => {
    if (authError) {
      setVerificationMessage(authError);
    }
  }, [authError]);

  if (isVerifying) {
      return (
        <Container>
          <Message>Nous vérifions votre email... Veuillez patienter.</Message>
        </Container>
      );
    }

    const getAlertMessage = () => {
      return "Aucun compte n'est rattaché à cette adresse email.";
    };

    return (
      <Wrapper>
        <ReturnButton onClick={handleBack}>
          <IoIosArrowBack />
        </ReturnButton>
        {hasEmailBeenSent && !showVerificationView && (
          <NextButton onClick={handleNext}>
            <IoIosArrowForward />
          </NextButton>
        )}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <Container>
          <Title>
            Connecte toi à ton compte
          </Title>
          <SubTitle>
            {showVerificationView ? 'Entrez le code de vérification' : 'Email'}
          </SubTitle>
          <EmailLoginContainer>
            <Form onSubmit={showVerificationView ? handleVerificationSubmit : handleEmailSubmit}>
              <Input
                type={showVerificationView ? "text" : "email"}
                autoComplete={showVerificationView ? "off" : "email"}
                placeholder={showVerificationView ? "Entrez le code à 6 chiffres" : "Entrez votre email"}
                value={showVerificationView ? verificationCode : email}
                onChange={showVerificationView ? handleVerificationCodeChange : handleEmailChange}
                disabled={!showVerificationView && countdown > 0}
                maxLength={showVerificationView ? 6 : undefined}
                pattern={showVerificationView ? "[0-9]*" : undefined}
                inputMode={showVerificationView ? "numeric" : "email"}
                required
              />
              <SendButton
                type="submit"
                disabled={
                  isLoading ||
                  (!showVerificationView ?
                    (!isValidInput || countdown > 0 || isEmailSubmitted) :
                    verificationCode.length !== 6)
                }
              >
                {isLoading ? 'Chargement...' : (showVerificationView ? 'Vérifier' : 'Envoyer le code')}
              </SendButton>
            </Form>
  
            {countdown > 0 && (
              <CountdownText>
                Vous pourrez renvoyer un code dans: {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}
              </CountdownText>
            )}
          </EmailLoginContainer>
  
          {!showVerificationView && (
            <>
              <Divider>
                <DividerLine />
                <DividerText>ou</DividerText>
                <DividerLine />
              </Divider>
  
              <ButtonGoogle
                onClick={handleGoogleLogin}
                disabled={isLoadingGoogle}
              >
                <GoogleButtonContent>
                  <GoogleIcon src={GoogleLogo} alt="Google" />
                  <ButtonTextGoogle>
                    {isLoadingGoogle ? 'Chargement...' : 'Google'}
                  </ButtonTextGoogle>
                </GoogleButtonContent>
              </ButtonGoogle>
              <ButtonApple
            onClick={handleAppleLogin}
            disabled={isLoading}
          >
            <AppleButtonContent>
              <AppleIcon src={AppleLogo} alt="Apple" />
              <ButtonTextApple>
                {isLoading ? 'Chargement...' : 'Apple'}
              </ButtonTextApple>
            </AppleButtonContent>
          </ButtonApple>
            </>
            )}
          {showEmailExistsAlert && (
            <AlertOverlay>
              <AlertBox>
                <AlertMessage>
                  {getAlertMessage()}
                </AlertMessage>
                <AlertButton onClick={handleAlertConfirm}>
                  Ok
                </AlertButton>
              </AlertBox>
            </AlertOverlay>
          )}
        </Container>
      </Wrapper>
    );
  };
  
  export default LoginPage;
