import React from 'react';
import { Trophy, Medal, Award } from 'lucide-react';
import {
  PodiumContainer,
  FormationName,
  FirstPlace,
  SecondPlace,
  ThirdPlace,
  CardContent,
  IconWrapper,
  PlaceLabel,
  PodiumCard,
} from './styles';

const Podium = ({ results }) => {
  const [first, second, third] = results;

  const handleClick = (link) => {
    if (link) {
      window.open(link, '_blank', 'noopener noreferrer');
    }
  };

  const renderPodiumStep = (result, place, Icon, StepComponent) => (
      <StepComponent onClick={() => handleClick(result.link)} className={result.link ? 'clickable' : ''}>
        <IconWrapper>
          <Icon size={24} />
        </IconWrapper>
          <FormationName>{result.formation}</FormationName>
          <PlaceLabel>{place}</PlaceLabel>
      </StepComponent>
  );

  return (
    <PodiumCard>
      <CardContent>
        <PodiumContainer>
          {renderPodiumStep(second, '', Medal, SecondPlace)}
          {renderPodiumStep(first, '', Trophy, FirstPlace)}
          {renderPodiumStep(third, '', Award, ThirdPlace)}
        </PodiumContainer>
      </CardContent>
    </PodiumCard>
  );
};

export default Podium;