import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-height: calc(var(--vh, 1vh) * 70);
  overflow-y: auto;
  background-color: ${props => props.theme.colors.background};
  border-radius: 25px;
  padding: ${props => props.theme.spacing.xlarge};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  margin-top: calc(var(--vh, 1vh) * 15);
  z-index: 2;

  /* Scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: ${props => props.theme.colors.primary} transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.primary};
    border-radius: 20px;
    border: 3px solid transparent;
  }

  ${props => !props.isMobile && `
    width: 70%;
    margin-top: calc(var(--vh, 1vh) * 10);
  `}
`;

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.xlarge};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  text-align: center;
  margin-bottom: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.colors.primary};
`;

export const Subtitle = styled.p`
  font-size: ${props => props.theme.fontSizes.medium};
  text-align: center;
  color: ${props => props.theme.colors.text};
  margin-bottom: ${props => props.theme.spacing.large};
`;

export const TiersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.medium};
  width: 100%;
  margin-top: ${props => props.theme.spacing.medium};
`;

export const TierRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.medium};
  width: 100%;
`;

export const TierLabel = styled.div`
  min-width: 120px;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background-color: ${props => {
    switch(props.tier) {
      case 'green': return '#4CAF50';
      case 'yellow': return '#FFC107';
      case 'orange': return '#FF9800';
      case 'red': return '#f44336';
      default: return '#757575';
    }
  }};
`;

export const DropZone = styled.div`
  flex: 1;
  min-height: calc(var(--vh, 1vh) * 8);
  max-height: calc(var(--vh, 1vh) * 8);
  padding: ${props => props.theme.spacing.medium};
  border-radius: 15px;
  border: 2px ${props => props.$isDraggingOver ? 'solid' : 'dashed'};
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing.small};
  transition: all 0.3s ease;
  overflow-y: auto;

  /* Scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: ${props => props.theme.colors.primary} transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.primary};
    border-radius: 20px;
    border: 2px solid transparent;
  }

  ${({ tier, $isDraggingOver, theme }) => {
    const getStyles = (color) => `
      background-color: ${$isDraggingOver 
        ? `${color}25`
        : `${color}10`};
      border-color: ${color};
    `;

    switch (tier) {
      case 'green':
        return getStyles('#059669');
      case 'yellow':
        return getStyles('#d97706');
      case 'orange':
        return getStyles('#ea580c');
      case 'red':
        return getStyles('#dc2626');
      default:
        return getStyles('#4b5563');
    }
  }}
`;

export const RegionChip = styled.div`
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background: ${props => props.theme.colors.background};
  border-radius: 25px;
  box-shadow: ${props => props.$isDragging 
    ? '0 8px 12px rgba(0, 0, 0, 0.2)' 
    : '0 4px 6px rgba(0, 0, 0, 0.1)'};
  cursor: move;
  user-select: none;
  transition: all 0.3s ease;
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.text};
  margin: 2px;
  transform: ${props => props.$isDragging 
    ? 'scale(1.05)' 
    : props.$isHovered 
    ? 'translateY(-2px)' 
    : 'none'};

  &:hover {
    transform: ${props => props.$isDragging ? 'scale(1.05)' : 'translateY(-2px)'};
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

export const UnrankedSection = styled.div`
  margin-top: ${props => props.theme.spacing.large};
  width: 100%;
`;

export const SubmitButton = styled.button`
  width: auto;
  height: auto;
  border-radius: 25px;
  padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.large};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.primary};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: ${props => props.theme.spacing.large};
  margin-bottom: ${props => props.theme.spacing.medium};

  &:hover {
    background-color: ${props => props.theme.colors.accent};
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
  margin: ${props => props.theme.spacing.small} 0;
  padding: ${props => props.theme.spacing.small};
  background: ${props => `${props.theme.colors.error}15`};
  border-radius: 8px;
  font-size: ${props => props.theme.fontSizes.small};
`;