// index.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import compassImage from '../../assets/boussole.png';
import cartoonBubble from '../../assets/cartoon.png';
import {
  Container,
  Cursor,
  InputWrapper,
  ContentWrapper,
  LogoImage,
  SpeechBubbleText,
  InputContainer,
  StyledInput,
  ReturnButton,
  SubmitButton,
  TextMeasure
} from './styles';

const AccountCreation = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef(null);
  const measureRef = useRef(null);

  const updateCursorPosition = () => {
    if (measureRef.current) {
      const textWidth = measureRef.current.getBoundingClientRect().width;
      setCursorPosition(textWidth);
    }
  };

  const handleSubmit = () => {
    if (inputValue.trim()) {
      localStorage.setItem('pseudo', inputValue.trim());
      navigate('/chat');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      handleSubmit();
    }
  };

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    updateCursorPosition();
  }, [inputValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Container onClick={handleContainerClick}>
      <ContentWrapper>
        <LogoImage src={compassImage} alt="Compass Logo" />
        <SpeechBubbleText>
          Comment veux-tu<br /> être appelé ?
        </SpeechBubbleText>
        <InputContainer>
          <InputWrapper>
            <StyledInput
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInput}
              onKeyPress={handleKeyPress}
              autoFocus
            />
            <TextMeasure ref={measureRef}>{inputValue}</TextMeasure>
            <Cursor style={{ left: `${cursorPosition}px` }} />
          </InputWrapper>
        </InputContainer>
        <SubmitButton
          onClick={handleSubmit}
          show={inputValue.trim().length > 0}
        >
          Continuer
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default AccountCreation;

