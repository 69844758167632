import styled from 'styled-components';
import theme from '../../theme';



export const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  width: ${props => props.width || '50%'};
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const DownloadAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: ${theme.spacing.large};
  background-color: ${theme.colors.background};
`;

export const ContentWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.large};
`;

export const Title = styled.h1`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.xxlarge};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.medium};
`;

export const Description = styled.p`
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.large};
  color: ${theme.colors.gris};
  margin-bottom: ${theme.spacing.large};
  line-height: 1.5;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.medium};
  width: 100%;
  max-width: 300px;
`;

export const StoreButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.medium};
  background: ${theme.colors.primary};
  color: ${theme.colors.lightText};
  border: none;
  width: 100%;
  padding: ${theme.spacing.medium};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  img {
    height: 24px;
    width: auto;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  }
`;

export const QuizImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(255, 39, 104, 0.15);
  margin: ${theme.spacing.large} 0;
`;