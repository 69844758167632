// src/theme.js
const theme = {
  colors: {
    primary: '#FF2768',     // New pink color
    secondary: '#FF6B98',   // Lighter shade of primary
    accent: '#FF8CAD',      // Even lighter shade for accents
    background: '#FFF5F8',  // Very light pink background
    background_fonce: '#FFEAEE',
    noir: '#333333',        // Kept dark text for readability
    gris: '#6E6E6E',
    gris_clair: '#BFBFBF',
    gris_clair_clair: '#E3E3E3',
    lightText: '#ffffff',   // Kept white for contrast
    lightGray: '#FFE4EC',   // Light pink-tinted gray
    veryLightGray: '#FFF5F8', // Very light pink-tinted gray
    mediumGray: '#FF4F85',  // Medium pink-tinted gray
  },
  fonts: {
    main: "'Roboto', sans-serif",
    heading: "'Inter', sans-serif",
  },
  fontSizes: {
    xsmall: '0.75rem',
    small: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
    xlarge: '1.25rem',
    xxlarge: '1.5rem',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bigBold: 800,
  },
  spacing: {
    xxsmall: '0rem',
    xsmall: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem',
    xxlarge: '2.5rem',
    xxxlarge: '3rem',
    big: '3.5rem',
  },
  breakpoints: {
    mobile: '767px',
  },
};

export default theme;
