import styled, {css} from 'styled-components';
import { MenuButton } from '../sharedStyles';
import theme from '../../theme';

const baseChatStyles = css`
  background-color: ${props => props.theme.colors.background};
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.$user === 'You' ? '0.75rem 1rem' : '0.2rem 0.5rem 0.5rem'};
  margin-bottom: 0.75rem;
  border-radius: ${props => props.$user === 'You' ? '1.2rem' : '1rem'};
  background-color: ${props => props.$user === 'You' ? props.theme.colors.lightGray : 'transparent'};
  color: ${props => props.theme.colors.text};
  box-shadow: ${props => props.$user === 'You' ? '0 1px 2px rgba(0, 0, 0, 0.1)' : 'none'};
  width: ${props => props.$user === 'You' ? 'fit-content' : '95%'};
  max-width: ${props => props.$user === 'You' ? '75%' : '100%'};
  text-align: left;
  margin-left: ${props => props.$user === 'You' ? 'auto' : '0'};
  height: auto;
`;

export const MenuBand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing.small};
  background-color: ${props => props.theme.colors.background};
  position: sticky;
  top: ${theme.spacing.medium};
  z-index: 1;

  ${props => !props.isMobile &&`
  display: none;
         `}

`;

export const MarkdownBox = styled.div`
  overflow-x: auto;

  & > * {
    margin-bottom: 0.625rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }

  & th, & td {
    border: 1px solid ${props => props.theme.colors.primary};
    padding: ${props => props.theme.spacing.small};
    text-align: left;
  }

  & th {
    background-color: ${props => props.theme.colors.background};
    font-weight: bold;
  }

  & tr:nth-child(even) {
    background-color: ${props => props.theme.colors.background};
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  & code {
    background-color: ${props => props.theme.colors.background};
    padding: 0.125rem 0.25rem;
    border-radius: 4px;
    font-family: ${props => props.theme.fonts.main};
  }

  & pre {
    background-color: ${props => props.theme.colors.background};
    padding: ${props => props.theme.spacing.medium};
    border-radius: 4px;
    overflow-x: auto;
  }
`;

export const Container = styled.div`
  ${baseChatStyles}
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  position: relative;

  // Apply blur effect when quiz-button is highlighted in tutorial mode
  ${props => props.blurChat && css`
    filter: blur(4px); // Adjust the blur intensity as needed
    pointer-events: none;
    opacity: 0.3;
  `}
`;

// Updated ChatContainer with conditional blur effect
export const ChatContainer = styled.div`
  ${baseChatStyles}
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: ${props => props.isSideMenuOpen && !props.isMobile ? 'calc(67% - 0)' : '80%'};
  margin-left: ${props => props.isSideMenuOpen && !props.isMobile ? '300px' : '10%'};
  border-radius: 12px;
  position: relative;
  overflow-y: auto;
  padding: ${props => props.theme.spacing.medium};

  ${props => props.showWelcomeMenu && css`
    align-items: center;
  `}

  ${props => props.isMobile && css`
    position: absolute;
    bottom: calc(var(--bottom-menu-height, 60px) + var(--input-area-height, 50px));
    margin-left: 0;
    right: 0;
    width: 100%;
    padding-top: ${props.theme.spacing.big};
    padding-bottom: calc(${props.theme.spacing.xxsmall} + var(--bottom-menu-height - 10px, 30px));
    height: calc(100vh - var(--bottom-menu-height, 60px) - var(--input-area-height, 50px));
  `}
`;

export const Button = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.background};
  border: none;
  border-radius: 25%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 0.5rem;
  bottom: 0.38rem;
  transition: all 0.3s ease;
  padding: 0;
  opacity: 0;
  pointer-events: none;

  &:hover, &:active, &:focus {
      background-color: ${props => props.theme.colors.secondary};
      outline: none;
    }

  &:focus:not(:focus-visible) {
      outline: none;
    }

  ${props => props.show && css`
    opacity: 0.9;
    pointer-events: auto;
  `}

  ${props => props.isMobile && css`
    width: 2.25rem;
    height: 2.25rem;
    min-width: 2.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    position: absolute;
  `}
`;

export const NewChatIconButton = styled.button`
  position: absolute;
  top: ${theme.spacing.medium};
  right: 15%;
  padding: ${theme.spacing.small};
  background-color: transparent;
  color: ${theme.colors.primary};
  border: 0px solid ${theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  z-index: 2;

  &:hover {
    color: ${theme.colors.secondary};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ChatBox = styled.div`
  ${baseChatStyles}
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  overflow: hidden;
  width: ${props => props.isSideMenuOpen && !props.isMobile ? 'calc(100% - 300px)' : '100%'};
  margin-left: ${props => props.isSideMenuOpen && !props.isMobile ? '300px' : '0'};
  border-radius: 12px;

  ${props => props.isMobile && css`
    border-radius: 0;
    height: calc(100vh - var(--bottom-menu-height, 60px) - var(--input-area-height, 50px));
  `}
`;

export const ChatHistory = styled.div`
  ${baseChatStyles}
  flex: 1;
  overflow-y: auto;
  padding: ${props => props.theme.spacing.medium};
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  max-height: calc(100vh - var(--bottom-menu-height, 60px) - var(--header-height, 60px) - var(--input-area-height, 50px));

  ${props => props.isMobile && css`
    padding: ${props.theme.spacing.small};
    padding-bottom: calc(${props.theme.spacing.large} + var(--bottom-menu-height, 60px));
  `}

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.mediumGray};
    border-radius: 4px;

    &:hover {
      background-color: ${props => props.theme.colors.darkGray};
    }
  }
`;

export const InputAreaWrapper = styled.div`
  ${baseChatStyles}
  width: 100%;
  padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.small};
  max-width: ${props => props.isSideMenuOpen && !props.isMobile ? 'calc(100% - 300px)' : '100%'};
  margin: 0 auto;
  position: fixed;
  left: ${props => props.isSideMenuOpen && !props.isMobile ? '300px' : '0'};
  right: 0;
  bottom: ${props => props.isMobile ?
    `calc(var(--bottom-menu-height, 60px))` :
    '10px'};
  z-index: 10;

  ${props => props.isMobile && css`
    padding: ${props.theme.spacing.xsmall};
    min-height: var(--input-area-height, 50px);
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  `}
`;

export const InputArea = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: ${props => props.isSideMenuOpen && !props.isMobile ? '67%' : '67%'};
  margin: 0 auto;
  position: relative;
  box-shadow: none;

  ${props => props.isMobile && css`
      flex-direction: row;
      align-items: flex-end;
      width: 95%;
      max-width: 100%;
      box-shadow: none;
      padding: 0;
      position: relative;
    `}
`;

export const Input = styled.textarea`
  flex: 1;
  padding: ${props => props.theme.spacing.medium};
  min-height: 3.5rem;
  max-height: 150px;
  border-radius: 25px;
  font-size: ${props => props.theme.fontSizes.medium};
  font-family: ${props => props.theme.fonts.main};
  background-color: ${props => props.theme.colors.lightGray};
  color: ${props => props.theme.colors.text};
  resize: none;
  overflow-y: auto;
  width: calc(100% - 60px);
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

  &::placeholder {
    color: ${props => props.theme.colors.mediumGray};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:focus {
    outline: none;
  }

  ${props => props.isMobile && css`
    font-size: ${props.theme.fontSizes.small};
    padding: ${props.theme.spacing.small} ${props.theme.spacing.medium};
    min-height: 2.4rem;
    border-radius: 12px;
    margin-bottom: calc(var(--bottom-menu-height, 60px) + 10px);
  `}

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.mediumGray};
    border-radius: 4px;

    &:hover {
      background-color: ${props => props.theme.colors.darkGray};
    }
  }
`;


export const StyledMenuButton = styled(MenuButton)`
position: absolute;
top: ${theme.spacing.medium};
left: ${theme.spacing.medium};
z-index: 2;
`;


export const SourcesContainer = styled.div`
  padding: 8px 16px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SourceLink = styled.a`
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  color: #1565c0;
  text-decoration: none;
  font-size: 0.9em;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }
`;
