import React from 'react';
import {
  DownloadAppContainer,
  Title,
  Description,
  ButtonsContainer,
  StoreButton,
  QuizImage,
  ContentWrapper
} from './styles';
import playstoreIcon from '../../assets/logo_playstore.png';
import appstoreIcon from '../../assets/logo_appstore.png';
import quizScreenshot from '../../assets/quiz-screenshot.png';

const DownloadApp = () => {
  const handlePlayStoreClick = () => {
    window.open('https://play.google.com/store/apps/your-app-link', '_blank');
  };

  const handleAppStoreClick = () => {
    window.open('https://apps.apple.com/app/your-app-link', '_blank');
  };

  return (
    <DownloadAppContainer>
      <ContentWrapper>
        <Title>Download the App</Title>
        <Description>
          Access the quiz and classement features through our mobile application
        </Description>
        <QuizImage src={quizScreenshot} alt="Quiz Screenshot" />
        <ButtonsContainer>
          <StoreButton onClick={handlePlayStoreClick}>
            <img src={playstoreIcon} alt="Play Store" />
            Get it on Play Store
          </StoreButton>
          <StoreButton onClick={handleAppStoreClick}>
            <img src={appstoreIcon} alt="App Store" />
            Download on App Store
          </StoreButton>
        </ButtonsContainer>
      </ContentWrapper>
    </DownloadAppContainer>
  );
};

export default DownloadApp;