import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ChatDiscussion from './components/ChatDiscussion';
import SideMenu from './components/SideMenu';
import BottomMenu from './components/BottomMenu';
import LoginPage from './components/LoginPage';
import FirstLoginAccount from './components/FirstLoginAccount';
import Quiz from './components/Quiz';
import Profile from './components/Classement';
import Settings from './components/Settings';
import MobileHistoryTab from './components/MobileHistoryTab';
import { AuthProviderWithNavigate, useAuth } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import AppLoadingHandler from './contexts/AppLoadingHandler';
import useDeviceType from './components/useDeviceType';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import PWAInstallPrompt from './components/PWAInstallPrompt';
import { LoadingContainer, LoadingBar } from './components/LoadingStyles';
import './App.css';
import { QuizProvider } from './contexts/QuizContext';
import FirstPage from './components/FirstPage';
import AccountCreation from './components/AccountCreation';
import DownloadApp from './components/DownloadApp';
import GenderSelection from './components/GenderSelection';
import UserTypeSelection from './components/UserTypeSelection';


function App() {
  const { user, loading, isAuthenticated } = useAuth();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  const { isMobile } = useDeviceType();
  const location = useLocation();
  const chatDiscussionRef = useRef();

  const toggleMenu = () => {
    setIsSideMenuOpen(prevState => !prevState);
  };

  const toggleMobileHistory = () => {
    setIsMobileHistoryOpen(!isMobileHistoryOpen);
  };

  const handleDiscussionSelect = (discussion) => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.loadDiscussion(discussion.id);
    }
  };

  const handleRestartChat = useCallback(() => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.resetChat();
    }
  }, []);

  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateViewportHeight();
    window.addEventListener('resize', updateViewportHeight);
    window.addEventListener('orientationchange', () => {
      setTimeout(updateViewportHeight, 100);
    });

    return () => {
      window.removeEventListener('resize', updateViewportHeight);
      window.removeEventListener('orientationchange', updateViewportHeight);
    };
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </ThemeProvider>
    );
  }

  // If not authenticated, show FirstPage and allowed routes
  if (!isAuthenticated) {
    return (
      <ThemeProvider theme={theme}>
        <AppLoadingHandler>
          <GlobalStyles />
          <Routes>
            <Route path="/" element={<FirstPage />} />
            <Route path="/account-creation" element={<AccountCreation />} />
            <Route path="/gender-selection" element={<GenderSelection />} />
            <Route path="/user_type-selection" element={<UserTypeSelection />} />
            <Route path="/first-login" element={<FirstLoginAccount />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AppLoadingHandler>
      </ThemeProvider>
    );
  }

  // Authenticated user view
  return (
    <ThemeProvider theme={theme}>
      <AppLoadingHandler>
        <GlobalStyles />
        <AuthProviderWithNavigate>
          <ChatProvider>
            <QuizProvider>
              <div className="App">
                <PWAInstallPrompt />
                <SideMenu
                  isOpen={isSideMenuOpen}
                  onToggle={toggleMenu}
                  onDiscussionSelect={handleDiscussionSelect}
                  onRestartChat={handleRestartChat}
                />
                <main className={`content-area ${isMobile ? 'mobile' : 'desktop'} ${isSideMenuOpen ? 'side-menu-open' : ''}`}>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/account-creation" element={<AccountCreation />} />
                    <Route path="/verify-email" element={<LoginPage />} />
                    <Route
                      path="/chat"
                      element={
                        <ChatDiscussion
                          ref={chatDiscussionRef}
                          toggleMobileHistory={toggleMobileHistory}
                          isSideMenuOpen={isSideMenuOpen}
                          isMobile={isMobile}
                        />
                      }
                    />
                    <Route
                      path="/quiz"
                      element={!isMobile ? <DownloadApp /> : <Quiz />}
                    />
                    <Route
                      path="/classement"
                      element={!isMobile ? <DownloadApp /> : <Profile />}
                    />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/" element={<Navigate to="/chat" replace />} />
                  </Routes>
                </main>
                {isMobile && (
                  <>
                    <BottomMenu user={user} />
                    {location.pathname === '/chat' && (
                      <MobileHistoryTab
                        isOpen={isMobileHistoryOpen}
                        onClose={toggleMobileHistory}
                      />
                    )}
                  </>
                )}
              </div>
            </QuizProvider>
          </ChatProvider>
        </AuthProviderWithNavigate>
      </AppLoadingHandler>
    </ThemeProvider>
  );
}

export default App;