import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

export const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const PodiumCard = styled.div`
  background: linear-gradient(135deg, ${theme.colors.background}, ${theme.colors.backgroundDark});
  border-radius: 16px;
  box-shadow: 0 5px 32px rgba(0, 0, 0, 0.15);
  margin-top: ${theme.spacing.xlarge};
`;

export const PodiumContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  animation: ${slideUp} 0.6s ease-out;
  padding: ${theme.spacing.medium};
  gap: ${theme.spacing.small};
`;


export const PodiumStep = styled.div`
  width: 100%; // Slightly narrower than the wrapper to ensure proper spacing
  border-radius: 12px 12px 0 0;
  transition: all 0.3s ease;
  position: relative;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  padding-top: 10px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden; // This contains the shimmer effect

  &.clickable {
    &:hover {
      transform: translateY(-5px);
      filter: brightness(1.1);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    animation: ${shimmer} 2s infinite;
    pointer-events: none; // Ensures the shimmer doesn't interfere with clicks
  }
`;

export const FirstPlace = styled(PodiumStep)`
  height: 180px;
  background: linear-gradient(45deg, #FFD700, #FFC800);
  box-shadow: 0 8px 24px rgba(255, 215, 0, 0.3);
`;

export const SecondPlace = styled(PodiumStep)`
  height: 130px;
  background: linear-gradient(45deg, #C0C0C0, #E0E0E0);
  box-shadow: 0 8px 24px rgba(192, 192, 192, 0.3);
`;

export const ThirdPlace = styled(PodiumStep)`
  height: 100px;
  background: linear-gradient(45deg, #CD7F32, #DFA878);
  box-shadow: 0 8px 24px rgba(205, 127, 50, 0.3);
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFD700;
  transition: transform 0.3s ease;
  z-index: 2;
  pointer-events: none; // Prevents icon from interfering with clicks

  .clickable:hover & {
    transform: translateX(-50%) scale(1.1);
  }
`;

export const StepContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: ${theme.spacing.small};
  width: 100%;
  pointer-events: none; // Ensures text doesn't interfere with clicks
`;

export const FormationName = styled.span`
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  margin-bottom: ${theme.spacing.xsmall};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.text};
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

export const PlaceLabel = styled.span`
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.textLight};
  opacity: 0.9;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;