import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../useDeviceType';
import {
  Container,
  SettingsButton,
  SettingsIcon,
  RankingList,
  RankingItem,
  RankingNumber,
  RankingLink,
  ScrollableRankings,
  NoResultsMessage,
} from './styles';
import { LoadingContainer, LoadingBar } from '../LoadingStyles';
import Podium from '../Podium';
import apiCall from '../../utils/api';

const Classement = () => {
  const { user } = useAuth();
  const [rankings, setRankings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  useEffect(() => {
    const initializeRankings = async () => {
      if (user && user.pseudo) {
        await fetchRankings();
      } else {
        setIsLoading(false);
      }
    };

    initializeRankings();
  }, [user]);

  const fetchRankings = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/results/`, 'GET');
      if (response.rankings) {
        setRankings(response.rankings);
      }
    } catch (error) {
      console.error('Error fetching rankings:', error);
      setRankings(null);
    } finally {
      setIsLoading(false);
    }
  };

  const getTop3Formations = () => {
    if (!rankings) return [
      { formation: "Pas encore de formation", link: null },
      { formation: "Pas encore de formation", link: null },
      { formation: "Pas encore de formation", link: null }
    ];

    return [
      {
        formation: rankings.top1?.name || "Pas encore de formation",
        link: rankings.top1?.url || null
      },
      {
        formation: rankings.top2?.name || "Pas encore de formation",
        link: rankings.top2?.url || null
      },
      {
        formation: rankings.top3?.name || "Pas encore de formation",
        link: rankings.top3?.url || null
      }
    ];
  };

  const getRemainingRankings = () => {
    if (!rankings) return [];

    return Object.entries(rankings)
      .filter(([key]) => {
        const num = parseInt(key.replace('top', ''));
        return num > 3 && num <= 50;
      })
      .map(([key, value]) => ({
        number: parseInt(key.replace('top', '')),
        link: value.url,
        formation: value.name
      }));
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleRestartQuiz = () => {
    navigate('/quiz');
  };

  const handleFormationClick = (link, event) => {
    if (!link) {
      event.preventDefault();
      return;
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingBar />
      </LoadingContainer>
    );
  }

  return (
    <Container isMobile={isMobile}>
      <SettingsButton
        onClick={handleSettingsClick}
        isMobile={isMobile}
      >
        <SettingsIcon isMobile={isMobile} />
      </SettingsButton>

      <Podium
        results={getTop3Formations()}
        onRestartQuiz={handleRestartQuiz}
        isMobile={isMobile}
      />

      {rankings ? (
        <ScrollableRankings isMobile={isMobile}>
          <RankingList>
            {getRemainingRankings().map(({ number, link, formation }) => (
              <RankingItem
                key={number}
                isMobile={isMobile}
              >
                <RankingNumber isMobile={isMobile}>
                  {number}
                </RankingNumber>
                <RankingLink
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => handleFormationClick(link, e)}
                >
                  {formation || "Nom de la formation indisponible"}
                </RankingLink>
              </RankingItem>
            ))}
          </RankingList>
        </ScrollableRankings>
      ) : (
        <NoResultsMessage isMobile={isMobile}>
          {user?.pseudo ? (
            <>
              Vous n'avez pas encore de résultats de quiz.
              <br />
              Essayez de répondre à un quiz pour voir vos résultats ici !
            </>
          ) : (
            "Connectez-vous pour voir vos résultats !"
          )}
        </NoResultsMessage>
      )}
    </Container>
  );
};

export default Classement;