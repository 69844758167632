// index.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomMenuContainer, MenuButton } from './styles';
import { TbSwipe } from "react-icons/tb";
import { HiOutlineChat, HiChat } from "react-icons/hi";
import { PiRanking, PiRankingFill } from "react-icons/pi";
import { useTheme } from 'styled-components';
import useDeviceType from '../useDeviceType';

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isMobile } = useDeviceType();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const getIconColor = (path) => {
    if (path === '/classement') {
      return (location.pathname === '/classement' || location.pathname === '/login')
        ? theme.colors.primary
        : theme.colors.mediumGray;
    }
    return location.pathname === path ? theme.colors.primary : theme.colors.mediumGray;
  };

  const isActive = (path) => {
    if (path === '/classement') {
      return location.pathname === '/classement' || location.pathname === '/login';
    }
    return location.pathname === path;
  };

  if (!isMobile) return null;

  return (
    <BottomMenuContainer>
      <MenuButton
        onClick={() => handleNavigation('/quiz')}
        active={isActive('/quiz')}
      >
        {isActive('/quiz') ? (
          <TbSwipe size={27} color={getIconColor('/quiz')} />
        ) : (
          <TbSwipe size={27} color={getIconColor('/quiz')} />
        )}
      </MenuButton>
      <MenuButton
        onClick={() => handleNavigation('/chat')}
        active={isActive('/chat')}
        center
      >
        {isActive('/chat') ? (
          <HiChat size={27} color={getIconColor('/chat')} />
        ) : (
          <HiOutlineChat size={27} color={getIconColor('/chat')} />
        )}
      </MenuButton>
      <MenuButton
        onClick={() => handleNavigation('/classement')}
        active={isActive('/classement')}
      >
        {isActive('/classement') ? (
          <PiRankingFill size={27} color={getIconColor('/classement')} />
        ) : (
          <PiRanking size={27} color={getIconColor('/classement')} />
        )}
      </MenuButton>
    </BottomMenuContainer>
  );
};

export default BottomMenu;