import styled, { keyframes, css } from 'styled-components';
import { FiMoreVertical } from "react-icons/fi";
import theme from '../../theme';

// Animations
export const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Base styles for consistent mobile handling
const mobileStyles = css`
  ${props => props.isMobile && css`
    padding-bottom: calc(var(--bottom-menu-height, 60px) + ${theme.spacing.medium});
  `}
`;

// Container styles
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => props.theme.spacing.large};
  max-width: 100%;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  background-color: ${props => props.theme.colors.background};

  ${props => props.isMobile && css`
    padding: ${props.theme.spacing.medium};
    padding-bottom: calc(var(--bottom-menu-height, 60px) + ${theme.spacing.large});
    height: calc(var(--vh, 1vh) * 100 - var(--bottom-menu-height, 60px));
  `}
`;

export const ScrollableRankings = styled.div`
  margin-top: 1rem;
  max-height: ${props => props.isMobile ?
    'calc(100vh - var(--bottom-menu-height, 60px) - 200px)' :
    '400px'
  };
  overflow-y: auto;
  width: 100%;
  background: transparent;
  border-radius: 12px;
  padding-bottom: ${props => props.isMobile ?
    'var(--bottom-menu-height, 40px)' :
    '0'
  };
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.lightGray};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.mediumGray};
    border-radius: 4px;

    &:hover {
      background: ${props => props.theme.colors.darkGray};
    }
  }

  ${props => props.isMobile && css`
    & > ul {
      flex: 0 0 auto;
    }
  `}
`;

// Rankings list and items
export const RankingList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const RankingItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${props => props.isMobile ? 
    props.theme.spacing.medium : 
    '1rem'
  };
  margin-bottom: 0.4rem;
  background: ${props => props.theme.colors.lightGray};
  border-radius: 8px;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(3px);
    background: ${props => props.theme.colors.veryLightGray};
  }

  ${props => props.isMobile && css`
    padding: ${props.theme.spacing.small};
  `}
`;

// Classement specific styles
export const ClassementContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: ${props => props.isMobile ? '0' : '-60px'} auto 0;
  padding: ${theme.spacing.large};
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(255, 39, 104, 0.1);
  
  ${props => props.isMobile && css`
    padding: ${theme.spacing.medium};
    border-radius: 12px;
    margin-bottom: calc(var(--bottom-menu-height, 60px) + ${theme.spacing.medium});
  `}
`;

export const ClassementTitle = styled.h2`
  color: ${theme.colors.text};
  font-size: ${props => props.isMobile ? 
    theme.fontSizes.large : 
    theme.fontSizes.xlarge
  };
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  margin-bottom: ${theme.spacing.large};
`;

export const ClassementList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.medium};
  padding-bottom: ${props => props.isMobile ? 
    'calc(var(--bottom-menu-height, 60px) + 20px)' : 
    '0'
  };
`;

export const ClassementItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.isMobile ? 
    theme.spacing.small : 
    theme.spacing.medium
  };
  background-color: ${theme.colors.veryLightGray};
  border-radius: 12px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(8px);
    background-color: ${theme.colors.lightGray};
  }
`;

// Settings button positioning
export const SettingsButton = styled.button`
  position: fixed;
  top: ${theme.spacing.medium};
  right: ${props => props.isMobile ? '10px' : '20px'};
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  z-index: 11;
`;

// Utility components
export const RankingLink = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  flex: 1;

  &:hover {
    text-decoration: underline;
  }
`;

export const RankingNumber = styled.span`
  min-width: 2rem;
  margin-right: ${props => props.isMobile ? '0.5rem' : '1rem'};
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`;

export const Position = styled.span`
  min-width: ${props => props.isMobile ? '32px' : '40px'};
  height: ${props => props.isMobile ? '32px' : '40px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.lightText};
  border-radius: 50%;
  font-weight: ${theme.fontWeights.bold};
  margin-right: ${props => props.isMobile ? 
    theme.spacing.small : 
    theme.spacing.medium
  };
`;

export const FormationInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.isMobile ? 
    theme.spacing.small : 
    theme.spacing.medium
  };
`;

export const Score = styled.span`
  font-weight: ${theme.fontWeights.semiBold};
  color: ${theme.colors.primary};
  ${props => props.isMobile && css`
    font-size: ${theme.fontSizes.small};
  `}
`;

export const NoResultsMessage = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: ${props => props.theme.colors.text};
  font-style: italic;
  padding-bottom: ${props => props.isMobile ? 
    'calc(var(--bottom-menu-height, 60px))' :
    '0'
  };
`;

export const SettingsIcon = styled(FiMoreVertical)`
  font-size: ${props => props.isMobile ? '20px' : '24px'};
  color: ${props => props.theme.colors.primary};
`;