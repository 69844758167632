// UserTypeSelection/index.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  LogoImage,
  SpeechBubbleText,
  ReturnButton,
  SubmitButton
} from '../AccountCreation/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  LoadingOverlay,
  OptionsScrollContainer
} from './styles';
import compassImage from '../../assets/boussole.png';

const UserTypeSelection = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const typeOptions = [
    { value: 'seconde', label: 'Seconde' },
    { value: 'premiere', label: 'Première' },
    { value: 'terminale', label: 'Terminale' },
    { value: 'etudiant', label: 'Étudiant' },
    { value: 'parent', label: 'Parent' },
    { value: 'autre', label: 'Autre' }
  ];

  const handleSubmit = () => {
    if (!selectedType) return;

    setIsLoading(true);

    try {
      localStorage.setItem('userType', selectedType);
      navigate('/first-login'); // Update with your next route
    } catch (error) {
      console.error('Error saving user type to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        <LogoImage src={compassImage} alt="Compass Logo" />
        <SpeechBubbleText>
          Tu es...
        </SpeechBubbleText>

        <OptionsScrollContainer>
          <OptionsContainer>
            <ButtonsWrapper>
              {typeOptions.map((option) => (
                <OptionButton
                  key={option.value}
                  isSelected={selectedType === option.value}
                  onClick={() => setSelectedType(option.value)}
                  type="button"
                  aria-pressed={selectedType === option.value}
                >
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          onClick={handleSubmit}
          show={selectedType !== null}
          disabled={isLoading}
          type="button"
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default UserTypeSelection;