// src/components/SaveLoginPopup/styles.js
import styled from 'styled-components';

export const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

export const PopUpContent = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding: ${props => props.theme.spacing.large};
  padding-top: calc(${props => props.theme.spacing.large} + 10px); // Increased top padding
  border-radius: ${props => props.theme.spacing.small};
  position: relative;
  max-width: 80%;
  width: 300px; // Set a fixed width
  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.spacing.xsmall};
  right: ${props => props.theme.spacing.xsmall};
  background: none;
  border: none;
  font-size: ${props => props.theme.fontSizes.xlarge};
  cursor: pointer;
  color: ${props => props.theme.colors.text};
`;


export const LoginLink = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border: none;
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  border-radius: ${props => props.theme.spacing.small};
  cursor: pointer;
  margin-top: ${props => props.theme.spacing.small};
  font-size: ${props => props.theme.fontSizes.medium};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.secondary || props.theme.colors.primary};
  }
`;