// GenderSelection/index.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  LogoImage,
  SpeechBubbleText,
  ReturnButton,
  SubmitButton
} from '../AccountCreation/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  LoadingOverlay
} from './styles';
import compassImage from '../../assets/boussole.png';

const GenderSelection = () => {
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const genderOptions = [
    { value: 'man', label: 'Un homme' },
    { value: 'woman', label: 'Une femme' },
    { value: 'other', label: 'Je ne souhaite pas répondre à cette question' },
  ];

  const handleSubmit = () => {
    if (!selectedGender) return;

    setIsLoading(true);

    try {
      // Store the gender selection in localStorage
      localStorage.setItem('userGender', selectedGender);

      // Navigate to the next step
      navigate('/user_type-selection');
    } catch (error) {
      console.error('Error saving gender to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>

      <ContentWrapper>
        <LogoImage src={compassImage} alt="Compass Logo" />
        <SpeechBubbleText>
          Tu es...
        </SpeechBubbleText>

        <OptionsContainer>
          <ButtonsWrapper>
            {genderOptions.map((option) => (
              <OptionButton
                key={option.value}
                isSelected={selectedGender === option.value}
                onClick={() => setSelectedGender(option.value)}
                type="button"
                aria-pressed={selectedGender === option.value}
              >
                {option.label}
              </OptionButton>
            ))}
          </ButtonsWrapper>
        </OptionsContainer>

        <SubmitButton
          onClick={handleSubmit}
          show={selectedGender !== null}
          disabled={isLoading}
          type="button"
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default GenderSelection;
