// src/components/QuestionPopup/index.js
import React from 'react';
import { PopupOverlay, PopupContent, PopupText, PopupButton } from './styles';

const QuestionPopup = ({ isOpen, onClose, type }) => {
  if (!isOpen) return null;

  const getMessage = () => {
    if (type === 'quiz2Progress') {
      return 'Plus que 10 questions avant les premiers résultats!';
    }
    return "Tes premiers résultats sont disponibles ! Retrouve les sur l'onglet Classement ou continue le swipe.";
  };

  return (
    <PopupOverlay>
      <PopupContent>
        <PopupText>{getMessage()}</PopupText>
        <PopupButton onClick={onClose}>OK</PopupButton>
      </PopupContent>
    </PopupOverlay>
  );
};

export default QuestionPopup;